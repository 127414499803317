// ? These components function with alpine + tailwind UI components
import Swiper from 'swiper'
import {Navigation, Pagination, Mousewheel, EffectCreative, Autoplay, FreeMode} from 'swiper/modules'

import flatpickr from "flatpickr"
import {Dutch} from "flatpickr/dist/l10n/nl.js"

window.obaia.Components = {},
    window.obaia.Components.typingText = function (options = {}) {
        return {
            text: options.text ?? '',
            textArray: options.textArray ?? [],
            textIndex: options.textIndex ?? 0,
            charIndex: options.charIndex ?? 0,
            pauseEnd: options.pauseEnd ?? 1500,
            cursorSpeed: options.cursorSpeed ?? 420,
            pauseStart: options.pauseStart ?? 20,
            typeSpeed: options.typeSpeed ?? 110,
            backgroundColors: options.backgroundColors ?? [],
            direction: 'forward',
            typingInterval: null,
            init() {
                const cursor = this.$refs.cursor
                if (cursor) {
                    setInterval(() => {
                        if (cursor.classList.contains('hidden')) {
                            cursor.classList.remove('hidden')
                        } else {
                            cursor.classList.add('hidden')
                        }
                    }, this.cursorSpeed);
                }
                this.typingInterval = setInterval(() => this.startTyping(), this.typeSpeed)

                // Init the colors
                this.$refs.text.classList.add('text-almelo-blue-900')
                this.$refs.spantext.classList.add('text-white')
                this.$watch(
                    'textIndex', () => {
                        this.$refs.background.classList.remove(this.$refs.background.className.match(/(?:bg-?)[^\s]+/gi)[0]);
                        this.$refs.background.classList.add(this.backgroundColors[this.textIndex]);

                        this.$refs.text.className = ''
                        this.$refs.spantext.className = ''
                        if (this.backgroundColors[this.textIndex] === 'bg-almelo-purple') {
                            this.$refs.text.classList.add('text-white')
                            this.$refs.spantext.classList.add('text-almelo-orange')
                        } else {
                            this.$refs.text.classList.add('text-almelo-blue-900')
                            this.$refs.spantext.classList.add('text-white')
                        }
                    }
                )
            },
            startTyping() {
                let current = this.textArray[this.textIndex]
                if (this.charIndex > current.length) {
                    this.direction = 'backward'
                    clearInterval(this.typingInterval)
                    setTimeout(() => {
                        this.typingInterval = setInterval(() => this.startTyping(), this.typeSpeed);
                    }, this.pauseEnd);
                }
                this.text = current.substring(0, this.charIndex)
                if (this.direction === 'forward') {
                    this.charIndex += 1;
                } else {
                    if (this.charIndex === 0) {
                        this.direction = 'forward'
                        clearInterval(this.typingInterval)
                        setTimeout(() => {
                            this.textIndex += 1;
                            if (this.textIndex >= this.textArray.length) {
                                this.textIndex = 0;
                            }
                            this.typingInterval = setInterval(() => this.startTyping(), this.typeSpeed);
                        }, this.pauseStart);
                    }
                    this.charIndex -= 1;
                }
            }
        }
    },
    window.obaia.Components.datepicker = function (options) {
        return {
            // value: Date.now(),
            value: '',
            init() {
                if (this.$el.value != '') {
                    this.value = this.$el.value
                }
                let picker = flatpickr(this.$el, {
                    mode: options.mode,
                    dateFormat: options.format ?? 'd-m-Y',
                    inline: options.inline ?? false,
                    defaultDate: this.value,
                    minDate: "today",
                    disableMobile: true,
                    // altInput: true,
                    // altFormat: options.altFormat ?? "d-m-Y",
                    // altInputClass: "user-readable input-date peer" + options.altColor ?? '',
                    locale: Dutch
                })
                this.$watch('value', () => picker.setDate(this.value))
            },
        }
    },
    window.obaia.Components.swiper = (options) => {
        return {
            swiperConstance: '',
            toggleAutoplay() {
                if (this.swiperConstance.autoplay.running) {
                    this.swiperConstance.autoplay.stop()
                } else {
                    this.swiperConstance.autoplay.start()
                }
            },
            init() {
                let initialSlideIndex = 0
                if (options.initialSlide !== '' && typeof options.initialSlide === 'string') {
                    initialSlideIndex = Array.prototype.indexOf.call(this.$el.querySelectorAll('.swiper-slide'), this.$el.querySelector('#' + options.initialSlide))
                }
                const thisElem = this.$el
                console.log(options.creativeEffect)
                window.addEventListener('load', () => {
                    this.swiperConstance = new Swiper(thisElem, {
                        modules: [Navigation, Pagination, Mousewheel, EffectCreative, Autoplay, FreeMode],
                        autoplay: options.autoplay ?? false,
                        speed: options.speed ?? 300,
                        freeMode: options.freeMode ?? false,
                        // cssMode: options.cssMode ?? false,
                        grabCursor: options.grabCursor ?? false,
                        loop: options.loop ?? false,
                        autoHeight: options.autoHeight ?? false,
                        slidesPerView: options.slidesPerView ?? 1,
                        centeredSlides: options.centeredSlides ?? false,
                        spaceBetween: options.spaceBetween ?? 16,
                        initialSlide: options.initialSlide ? initialSlideIndex : options.centeredSlides ? this.$el.querySelectorAll('.swiper-slide').length / 2 : 0,
                        mousewheel: {
                            forceToAxis: true,
                        },
                        navigation: {
                            prevEl: this.$refs.swiperPrev ?? thisElem.closest('section').querySelector('.js-swiper-button-prev'),
                            nextEl: this.$refs.swiperNext ?? thisElem.closest('section').querySelector('.js-swiper-button-next'),
                        },
                        pagination: options.pagination ? {
                            el: thisElem.closest('section').querySelector('.js-swiper-pagination'),
                            clickable: true,
                            renderBullet: function (index, className) {
                                let names = []
                                thisElem.querySelectorAll('.swiper-slide').forEach((slide) => {
                                    names.push(slide.getAttribute('data-name'))
                                })
                                return '<li class="cursor-pointer antialiased ' + className + '">' + names[index] + '</li>';
                            }
                        } : {},
                        breakpoints: {
                            ...options.breakpoints
                        },
                        on: {
                            init: function () {
                                if (options.autoplay) {
                                    this.autoplay.start()
                                }
                            },
                            autoplayResume: function () {
                                this.autoplay.start()
                            },
                            autoplayPause: function () {
                                this.autoplay.stop()
                            },
                        },
                    })
                    console.log(this.swiperConstance)
                })
            }
        }
    }

// window.obaia.Components.menu = function (e = {
//     open: !1
// }) {
//     return {
//         init() {
//             this.items = Array.from(this.$el.querySelectorAll('[role="menuitem"]')),
//                 this.$watch("open", (() => {
//                         this.open && (this.activeIndex = -1)
//                     }
//                 ))
//         },
//         activeDescendant: null,
//         activeIndex: null,
//         items: null,
//         open: e.open,
//         focusButton() {
//             this.$refs.button.focus()
//         },
//         onButtonClick() {
//             this.open = !this.open,
//             this.open && this.$nextTick((() => {
//                     this.$refs["menu-items"].focus()
//                 }
//             ))
//         },
//         onButtonEnter() {
//             this.open = !this.open,
//             this.open && (this.activeIndex = 0,
//                 this.activeDescendant = this.items[this.activeIndex].id,
//                 this.$nextTick((() => {
//                         this.$refs["menu-items"].focus()
//                     }
//                 )))
//         },
//         onArrowUp() {
//             if (!this.open)
//                 return this.open = !0,
//                     this.activeIndex = this.items.length - 1,
//                     void (this.activeDescendant = this.items[this.activeIndex].id);
//             0 !== this.activeIndex && (this.activeIndex = -1 === this.activeIndex ? this.items.length - 1 : this.activeIndex - 1,
//                 this.activeDescendant = this.items[this.activeIndex].id)
//         },
//         onArrowDown() {
//             if (!this.open)
//                 return this.open = !0,
//                     this.activeIndex = 0,
//                     void (this.activeDescendant = this.items[this.activeIndex].id);
//             this.activeIndex !== this.items.length - 1 && (this.activeIndex = this.activeIndex + 1,
//                 this.activeDescendant = this.items[this.activeIndex].id)
//         },
//         onClickAway(e) {
//             if (this.open) {
//                 const t = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
//                 this.open = !1,
//                 e.target.closest(t) || this.focusButton()
//             }
//         }
//     }
// }
window.obaia.Components.menu = function menu(options = {open: false}) {
    let pointer = useTrackedPointer()

    return {
        init() {
            this.items = Array.from(this.$el.querySelectorAll('[role="menuitem"]'))
            this.$watch('open', () => {
                if (this.open) {
                    this.activeIndex = -1
                }
            })
        },
        activeDescendant: null,
        activeIndex: null,
        items: null,
        open: options.open,
        focusButton() {
            this.$refs.button.focus()
        },
        onButtonClick() {
            this.open = !this.open
            if (this.open) {
                this.$nextTick(() => {
                    this.$refs['menu-items'].focus()
                })
            }
        },
        onButtonEnter() {
            this.open = !this.open
            if (this.open) {
                this.activeIndex = 0
                this.activeDescendant = this.items[this.activeIndex].id
                this.$nextTick(() => {
                    this.$refs['menu-items'].focus()
                })
            }
        },
        onArrowUp() {
            if (!this.open) {
                this.open = true
                this.activeIndex = this.items.length - 1
                this.activeDescendant = this.items[this.activeIndex].id

                return
            }

            if (this.activeIndex === 0) {
                return
            }

            this.activeIndex = this.activeIndex === -1 ? this.items.length - 1 : this.activeIndex - 1
            this.activeDescendant = this.items[this.activeIndex].id
        },
        onArrowDown() {
            if (!this.open) {
                this.open = true
                this.activeIndex = 0
                this.activeDescendant = this.items[this.activeIndex].id

                return
            }

            if (this.activeIndex === this.items.length - 1) {
                return
            }

            this.activeIndex = this.activeIndex + 1
            this.activeDescendant = this.items[this.activeIndex].id
        },
        onClickAway($event) {
            if (this.open) {
                const focusableSelector = [
                    '[contentEditable=true]',
                    '[tabindex]',
                    'a[href]',
                    'area[href]',
                    'button:not([disabled])',
                    'iframe',
                    'input:not([disabled])',
                    'select:not([disabled])',
                    'textarea:not([disabled])',
                ]
                    .map((selector) => `${selector}:not([tabindex='-1'])`)
                    .join(',')

                this.open = false

                if (!$event.target.closest(focusableSelector)) {
                    this.focusButton()
                }
            }
        },

        onMouseEnter(evt) {
            pointer.update(evt)
        },
        onMouseMove(evt, newIndex) {
            // Only highlight when the cursor has moved
            // Pressing arrow keys can otherwise scroll the container and override the selected item
            if (!pointer.wasMoved(evt)) return
            this.activeIndex = newIndex
        },
        onMouseLeave(evt) {
            // Only unhighlight when the cursor has moved
            // Pressing arrow keys can otherwise scroll the container and override the selected item
            if (!pointer.wasMoved(evt)) return
            this.activeIndex = -1
        },
    }
}

window.obaia.Components.popoverGroup = function () {
    return {
        __type: "popoverGroup",
        init() {
            let e = t => {
                    document.body.contains(this.$el) ? t.target instanceof Element && !this.$el.contains(t.target) && window.dispatchEvent(new CustomEvent("close-popover-group", {
                        detail: this.$el
                    })) : window.removeEventListener("focus", e, !0)
                }
            ;
            window.addEventListener("focus", e, !0)
        }
    }
}
    ,
    window.obaia.Components.popover = function ({open: e = !1, focus: t = !1} = {}) {
        const i = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
        return {
            __type: "popover",
            open: e,
            init() {
                t && this.$watch("open", (e => {
                        e && this.$nextTick((() => {
                                !function (e) {
                                    const t = Array.from(e.querySelectorAll(i));
                                    !function e(i) {
                                        void 0 !== i && (i.focus({
                                            preventScroll: !0
                                        }),
                                        document.activeElement !== i && e(t[t.indexOf(i) + 1]))
                                    }(t[0])
                                }(this.$refs.panel)
                            }
                        ))
                    }
                ));
                let e = i => {
                        if (!document.body.contains(this.$el))
                            return void window.removeEventListener("focus", e, !0);
                        let n = t ? this.$refs.panel : this.$el;
                        if (this.open && i.target instanceof Element && !n.contains(i.target)) {
                            let e = this.$el;
                            for (; e.parentNode;)
                                if (e = e.parentNode,
                                e.__x instanceof this.constructor) {
                                    if ("popoverGroup" === e.__x.$data.__type)
                                        return;
                                    if ("popover" === e.__x.$data.__type)
                                        break
                                }
                            this.open = !1
                        }
                    }
                ;
                window.addEventListener("focus", e, !0)
            },
            onEscape() {
                this.open = !1,
                this.restoreEl && this.restoreEl.focus()
            },
            onClosePopoverGroup(e) {
                e.detail.contains(this.$el) && (this.open = !1)
            },
            toggle(e) {
                this.open = !this.open,
                    this.open ? this.restoreEl = e.currentTarget : this.restoreEl && this.restoreEl.focus()
            }
        }
    }

function useTrackedPointer() {
    /** @type {[x: number, y: number]} */
    let lastPos = [-1, -1]

    return {
        /**
         * @param {PointerEvent} evt
         */
        wasMoved(evt) {
            let newPos = [evt.screenX, evt.screenY]

            if (lastPos[0] === newPos[0] && lastPos[1] === newPos[1]) {
                return false
            }

            lastPos = newPos
            return true
        },

        /**
         * @param {PointerEvent} evt
         */
        update(evt) {
            lastPos = [evt.screenX, evt.screenY]
        },
    }
}